// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
// import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
// import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
// import Projects from "layouts/dashboard/components/Projects";
// import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
// import DataTable from "examples/Tables/DataTable";
import Fetchdata, { Headers } from "../../hooks/Fetchdata";
import { endpoints } from "endpoints";
import { Table } from "react-bootstrap";
import Simpleloader from "components/Loader/Simpleloader";
import axios from "../../Schemas/Apiurl";
import { Card } from "@mui/material";
import { toast } from "react-toastify";
import useLoading from "components/Loader/useLoading";
import Loader from "components/Loader/Loader";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts";

function Dashboard() {
  // const { sales, tasks } = reportsLineChartData;
  const [selected, setselect] = useState("daily");
  const [selectedtype, setselecttype] = useState("user_data");
  const [selecteddate, setselectdate] = useState();
  // const userdata = useSelector((state) => state.auth.admin);
  // console.log(userdata);
  const { loading, startLoading, stopLoading } = useLoading();

  const {
    response: counts,
    isloading: isLoading,
    getdata,
  } = Fetchdata(
    endpoints.dashboard,
    {
      graph_type: selected,
      month: selecteddate,
      // graphName: selectedtype,
    },
    "POST"
  );

  const userchartData = {
    labels: counts?.data?.user_data?.map((item) => item.MonthYear || item.Day),
    datasets: {
      label: "Users",
      data: counts?.data?.user_data?.map((item) => item.TotalUsers),
    },
  };
  const categorychartData = {
    labels: counts?.data?.category_data?.map((item) => item.MonthYear),
    datasets: {
      label: "Users",
      data: counts?.data?.category_data?.map((item) => item.TotalCategories),
    },
  };
  const finalitemchartData = {
    labels: counts?.data?.final_item?.map((item) => item.MonthYear),
    datasets: {
      label: "Users",
      data: counts?.data?.final_item?.map((item) => item.TotalAccessories),
    },
  };

  useEffect(() => {
    getdata();
  }, [selected, selecteddate]);

  const options = [];
  const currentdate = new Date();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  };

  for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setMonth(currentdate.getMonth() - i);
    options.push(formatDate(date));
  }
  // console.log(counts);
  // const demochart = [
  //   {
  //     data: counts?.data?.user_data?.map((item) => item.TotalUsers),
  //     label: "Total Users",
  //   },
  //   {
  //     data: counts?.data?.user_data?.map((item) => item.ActiveUsers),
  //     label: "Active Users",
  //   },
  //   {
  //     data: counts?.data?.user_data?.map((item) => item.DeactiveUsers),
  //     label: "Deactive Users",
  //   },
  // ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="persons"
                color="success"
                // icon="leaderboard"
                title="Users"
                isLoading={isLoading ? true : false}
                count={counts?.data?.summary?.total_users || ""}
                activecount={counts?.data?.summary?.active_users || ""}
                deactivecount={counts?.data?.summary?.deactive_users || ""}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="categoryIcon"
                title="Brand Category"
                isLoading={isLoading}
                count={counts?.data?.summary?.total_brand_category || ""}
                activecount={counts?.data?.summary?.active_brand_category || ""}
                deactivecount={counts?.data?.summary?.deactive_brand_category || ""}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="categoryIcon"
                title="Brand Value"
                color="dark"
                isLoading={isLoading}
                count={counts?.data?.summary?.total_brand_value || ""}
                activecount={counts?.data?.summary?.active_brand_value || ""}
                deactivecount={counts?.data?.summary?.deactive_brand_value || ""}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="headphonesIcon"
                color="secondary"
                title="Accessories"
                isLoading={isLoading}
                count={counts?.data?.summary?.total_accessories || ""}
                activecount={counts?.data?.summary?.active_accessories || ""}
                deactivecount={counts?.data?.summary?.deactive_accessories || ""}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="categoryIcon"
                color="success"
                title="Categories"
                isLoading={isLoading}
                count={counts?.data?.summary?.total_categories || ""}
                activecount={counts?.data?.summary?.active_categories || ""}
                deactivecount={counts?.data?.summary?.deactive_categories || ""}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="feed"
                title="Certificate"
                isLoading={isLoading}
                count={counts?.data?.summary?.total_certificate || ""}
                activecount={counts?.data?.summary?.active_certificate || ""}
                deactivecount={counts?.data?.summary?.deactive_certificate || ""}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="contactsIcon"
                color="dark"
                title="Contact Us"
                isLoading={isLoading}
                contactus={true}
                activecount={counts?.data?.summary?.read_contact_us || ""}
                deactivecount={counts?.data?.summary?.not_read_contact_us || ""}
              />
            </MDBox>
          </Grid>
        </Grid>
        <div className="d-flex  align-items-center flex-wrap justify-content-end gap-2 w-100 mb-2 mt-3 typedropdown">
          <select
            name=""
            id=""
            value={selecteddate}
            onChange={(e) => setselectdate(e.target.value)}
          >
            {options.map((item, i) => {
              return (
                <option value={item} key={i}>
                  {item}
                </option>
              );
            })}
          </select>
          <select name="" id="" value={selected} onChange={(e) => setselect(e.target.value)}>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
          </select>
          {/* <select
            name=""
            id=""
            value={selectedtype}
            onChange={(e) => setselecttype(e.target.value)}
          >
            <option value="user_data">User data</option>
            <option value="category_data">Category data</option>
            <option value="final_item">Final item</option>
          </select> */}
        </div>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title={
                    selectedtype === "user_data"
                      ? "Users"
                      : selectedtype === "category_data"
                      ? "Category"
                      : selectedtype === "final_item"
                      ? "Final Item"
                      : null
                  }
                  // description="Last Campaign Performance"
                  // date="campaign sent 2 days ago"
                  chart={
                    selectedtype === "user_data"
                      ? userchartData
                      : selectedtype === "category_data"
                      ? categorychartData
                      : selectedtype === "final_item"
                      ? finalitemchartData
                      : null
                  }
                />
                {/* <Card>
                  <BarChart
                    // series={demochart}
                    // width={"100%"}
                    height={350}
                    dataset={demochart}
                  />
                </Card> */}
              </MDBox>
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Category"
                  // description={
                  //   <>
                  //     (<strong>+15%</strong>) increase in today sales.
                  //   </>
                  // }
                  // date="updated 4 min ago"
                  chart={categorychartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Final Item"
                  // description="Last Campaign Performance"
                  // date="just updated"
                  chart={finalitemchartData}
                />
              </MDBox>
            </Grid> */}
          </Grid>
        </MDBox>

        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {/* <Footer /> */}
      {loading && <Loader />}
    </DashboardLayout>
  );
}

export default Dashboard;
