// import { Delete } from "@mui/icons-material";
import { Card } from "@mui/material";
import Simpleloader from "components/Loader/Simpleloader";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Fetchdata from "hooks/Fetchdata";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

const Subscribers = () => {
  const [keyword, setkeyword] = useState("");
  const [pages, setpages] = useState(1);
  const [type, settype] = useState();
  const { response, isloading, getdata } = Fetchdata(
    endpoints.getsuscriberditailsadmin,
    { page: pages, search: keyword, type: type },
    "POST"
  );

  useEffect(() => {
    getdata();
  }, [type]);

  // search with debounce..................
  const debounceDelay = 500;
  useEffect(() => {
    let timeroutid;
    if (keyword) {
      if (timeroutid) {
        clearTimeout(timeroutid);
      }
      timeroutid = setTimeout(() => {
        getdata();
      }, debounceDelay);
    } else if (keyword == "") {
      getdata();
    }
    return () => {
      if (timeroutid) {
        clearTimeout(timeroutid);
      }
    };
  }, [keyword]);

  const handlesearch = (e) => {
    setkeyword(e.target.value);
  };

  const types = [
    { enum: "user", name: "User" },
    { enum: "brand", name: "Brand" },
    { enum: "influencer", name: "Influencer" },
    { enum: "store", name: "Store" },
    { enum: "genaral", name: "All service" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4.5}>
        <div className="d-flex align-items-center justify-content-between gap-2 mb-3 typedropdown">
          <MDInput type="text" onChange={handlesearch} name="search" label="Search" />
          <select value={type} name="" id="" onChange={(e) => settype(e.target.value)}>
            {types.map((item, i) => {
              return (
                <option key={i} value={item.enum}>
                  {" "}
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        {isloading ? (
          <Simpleloader style={{ height: "300px" }} />
        ) : (
          <>
            {response?.data?.length === 0 ? (
              <h1 className="text-center mt-3">No data</h1>
            ) : (
              <Card>
                <Table responsive="xxl" className="vouchertable mt-3">
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>Email</th>
                      <th>User type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {response?.data?.map((item, index) => {
                      return (
                        <tr className="" key={index}>
                          <td className="hover-color-change">{index + 1}</td>
                          <td className="hover-color-change">{item.email}</td>
                          <td className="hover-color-change">{item.user_type}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card>
            )}
          </>
        )}
        {response?.pagination?.total > 0 && response?.pagination?.total_pages > 0 && (
          <div className="d-flex align-items-center justify-content-center gap-1 mt-3 mb-3 ">
            <button
              className="createbrandbtn"
              disabled={pages === 1 ? true : false}
              onClick={() => (pages === 1 ? null : setpages((prev) => prev - 1))}
            >
              Prev
            </button>
            <span>{pages}</span>
            <button
              className="createbrandbtn"
              disabled={pages === response?.pagination?.total_pages ? true : false}
              onClick={() => setpages((prev) => prev + 1)}
            >
              Next
            </button>
          </div>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default Subscribers;
