/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
// import Billing from "layouts/billing";
// import RTL from "layouts/rtl";
// import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import Changepassword from "layouts/authentication/changepassword/index";
// import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import News from "pages/News/News";
import Addnews from "pages/News/Addnews";
import Editnews from "pages/News/Editnews";
import Privacypolicy from "pages/Privacypolicy/Privacypolicy";
import Termscondition from "pages/Termscondition/Termscondition";
import ContactUs from "pages/ContactUs/ContactUs";
import Fashioncategory from "pages/Fashion/Fashioncategory";
import AddCategory from "pages/Fashion/AddCategory";
import AddCategory2 from "pages/Category/AddCategory";
import AddSubCategory from "pages/Category/SubCategory/AddSubCategory";
import EditSubCategory from "pages/Category/SubCategory/EditSubCategory";
import EditCategory from "pages/Fashion/EditCategory";
import EditCategory2 from "pages/Category/EditCategory";
import Category from "pages/Category/Category";
import SubCategory from "pages/Category/SubCategory/SubCategory";
import Types from "pages/Category/Typess/Types";
import AddTypes from "pages/Category/Typess/AddTypes";
import EditTypes from "pages/Category/Typess/EditTypes";
import SubTypes from "pages/Category/SubTypes/SubTypes";
import AddSubtypes from "pages/Category/SubTypes/AddSubtypes";
import EditSubtypes from "pages/Category/SubTypes/EditSubtypes";
import FinalProduct from "pages/FinalProduct/FinalProduct";
import EditProduct from "pages/FinalProduct/EditProduct";
import AddProduct from "pages/FinalProduct/AddProduct";
import Certificate from "pages/Certificate/Certificate";
import AddCertificate from "pages/Certificate/AddCertificate";
import EditCertificate from "pages/Certificate/EditCertificate";
import BrandCategory from "pages/Brand/BrandCategory/BrandCategory";
import AddBrandCategory from "pages/Brand/BrandCategory/AddBrandCategory";
import EditBrandCategory from "pages/Brand/BrandCategory/EditBrandCategory";
import BrandValue from "pages/Brand/BrandValues/BrandValue";
import AddBrandvalue from "pages/Brand/BrandValues/AddBrandvalue";
import EditBrandvalue from "pages/Brand/BrandValues/EditBrandvalue";
import BrandSubvalue from "pages/Brand/BrandValues/BrandSubvalue/BrandSubvalue";
import AddBrandsubvalue from "pages/Brand/BrandValues/BrandSubvalue/AddBrandsubvalue";
import EditBrandsubvalue from "pages/Brand/BrandValues/BrandSubvalue/EditBrandsubvalue";
import Userlist from "pages/Users/Userlist";
import Brandlist from "pages/Users/Brandlist";
import Influencerlist from "pages/Users/Influencerlist";
import Storelist from "pages/Users/Storelist";
import Subscribers from "pages/Subscribers/Subscribers";
import Editbrand from "pages/Editbrand/Editbrand";
import UploadCSV from "pages/UploadCSV/UploadCSV";
import SendNewsletter from "pages/Subscribers/SendNewsletter";
import SubFinalProduct from "pages/FinalProduct/SubFinalProduct/SubFinalProduct";
import AddsubProduct from "pages/FinalProduct/SubFinalProduct/AddsubProduct";
import EditsubProduct from "pages/FinalProduct/SubFinalProduct/EditsubProduct";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Userlist />,
  },
  {
    type: "collapse",
    name: "Brands users",
    key: "brandlist",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/brandlist",
    component: <Brandlist />,
  },
  {
    type: "collapse",
    name: "Influencers users",
    key: "influencerlist",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/influencerlist",
    component: <Influencerlist />,
  },
  {
    type: "collapse",
    name: "Stores users",
    key: "storelist",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/storelist",
    component: <Storelist />,
  },
  {
    type: "collapse",
    name: "News",
    key: "news",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/news",
    component: <News />,
  },
  {
    type: "collapse",
    name: "Subscribers",
    key: "subscribers",
    icon: <Icon fontSize="small">subscriptionsIcon</Icon>,
    route: "/subscribers",
    component: <Subscribers />,
  },
  {
    type: "collapse",
    name: "Sendnewslater",
    key: "sendnewslater",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/sendnewslater",
    component: <SendNewsletter />,
  },
  {
    type: "subroute",
    name: "Categories",
    icon: <Icon fontSize="small">categoryIcon</Icon>,
    subRoutes: [
      {
        type: "collapse",
        name: "Fashion Category",
        key: "fashioncategory",
        // icon: <Icon fontSize="small">categoryIcon</Icon>,
        route: "/fashioncategory",
        component: <Fashioncategory />,
      },
      {
        type: "collapse",
        name: "Category",
        key: "category",
        // icon: <Icon fontSize="small">FiberManualRecordIcon</Icon>,
        route: "/category",
        component: <Category />,
      },
      {
        type: "collapse",
        name: "Sub Category",
        key: "subcategory",
        icon: <Icon fontSize="small">notifications</Icon>,
        route: "/subcategory",
        component: <SubCategory />,
      },
      {
        type: "collapse",
        name: "Types",
        key: "types",
        // icon: <Icon fontSize="small">notifications</Icon>,
        route: "/types",
        component: <Types />,
      },
      {
        type: "collapse",
        name: "Sub Types",
        key: "subtypes",
        // icon: <Icon fontSize="small">notifications</Icon>,
        route: "/subtypes",
        component: <SubTypes />,
      },
      {
        type: "collapse",
        name: "Final Product",
        key: "finalproduct",
        // icon: <Icon fontSize="small">notifications</Icon>,
        route: "/finalproduct",
        component: <FinalProduct />,
      },
      {
        type: "collapse",
        name: "Sub Final Product",
        key: "subfinalproduct",
        // icon: <Icon fontSize="small">notifications</Icon>,
        route: "/subfinalproduct",
        component: <SubFinalProduct />,
      },
    ],
  },
  // brand value.................
  {
    type: "subroute",
    name: "Brand",
    icon: <Icon fontSize="small">categoryIcon</Icon>,
    subRoutes: [
      {
        type: "collapse",
        name: "Brand Value",
        key: "brandvalue",
        // icon: <Icon fontSize="small">categoryIcon</Icon>,
        route: "/brandvalue",
        component: <BrandValue />,
      },
      {
        type: "collapse",
        name: "Brand Sub value",
        key: "brandsubvalue",
        // icon: <Icon fontSize="small">FiberManualRecordIcon</Icon>,
        route: "/brandsubvalue",
        component: <BrandSubvalue />,
      },
    ],
  },
  {
    type: "",
    name: "Brand Value",
    key: "brandvalue",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/brandvalue/Addbrandvalue",
    component: <AddBrandvalue />,
  },
  {
    type: "",
    name: "Brand Value",
    key: "brandvalue",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/brandvalue/Editbrandvalue",
    component: <EditBrandvalue />,
  },
  {
    type: "",
    name: "Brand Sub Value",
    key: "brandsubvalue",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/brandsubvalue/Addbrandsubvalue",
    component: <AddBrandsubvalue />,
  },
  {
    type: "",
    name: "Brand Sub Value",
    key: "brandsubvalue",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/brandsubvalue/Editbrandsubvalue",
    component: <EditBrandsubvalue />,
  },
  // brand value.................

  // certificate........................
  {
    type: "collapse",
    name: "Certificate",
    key: "certificate",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/certificate",
    component: <Certificate />,
  },
  {
    type: "",
    name: "Certificate",
    key: "certificate",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/certificate/Addcertificate",
    component: <AddCertificate />,
  },
  {
    type: "",
    name: "Certificate",
    key: "certificate",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/certificate/Editcertificate",
    component: <EditCertificate />,
  },

  // certificate........................

  // brand category..................
  {
    type: "collapse",
    name: "Brand Category",
    key: "brandcategory",
    icon: <Icon fontSize="small">categoryIcon</Icon>,
    route: "/brandcategory",
    component: <BrandCategory />,
  },
  {
    type: "",
    name: "Brand Category",
    key: "brandcategory",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/brandcategory/Addbrandcategory",
    component: <AddBrandCategory />,
  },
  {
    type: "",
    name: "Brand Category",
    key: "brandcategory",
    icon: <Icon fontSize="small">feed</Icon>,
    route: "/brandcategory/Editbrandcategory",
    component: <EditBrandCategory />,
  },
  // brand category..................

  // category.............................................
  {
    type: "",
    name: "Category",
    key: "category",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/category/Addcategory",
    component: <AddCategory2 />,
  },
  {
    type: "",
    name: "Category",
    key: "category",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/category/EditCategory",
    component: <EditCategory2 />,
  },
  {
    type: "",
    name: "Sub Category",
    key: "subcategory",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subcategory/Addsubcategory",
    component: <AddSubCategory />,
  },
  {
    type: "",
    name: "Sub Category",
    key: "subcategory",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subcategory/Editsubcategory",
    component: <EditSubCategory />,
  },
  {
    type: "",
    name: "Types",
    key: "types",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/types/Addtypes",
    component: <AddTypes />,
  },
  {
    type: "",
    name: "Types",
    key: "types",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/types/Edittypes",
    component: <EditTypes />,
  },
  {
    type: "",
    name: "Sub Types",
    key: "subtypes",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subtypes/AddSubtypes",
    component: <AddSubtypes />,
  },
  {
    type: "",
    name: "Sub Types",
    key: "subtypes",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subtypes/EditSubtypes",
    component: <EditSubtypes />,
  },
  {
    type: "",
    name: "Final product",
    key: "finalproduct",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/finalproduct/Editfinalproduct",
    component: <EditProduct />,
  },
  {
    type: "",
    name: "Final product",
    key: "finalproduct",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/finalproduct/Addfinalproduct",
    component: <AddProduct />,
  },
  {
    type: "",
    name: "Final product",
    key: "subfinalproduct",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subfinalproduct/Addsubfinalproduct",
    component: <AddsubProduct />,
  },
  {
    type: "",
    name: "Final product",
    key: "subfinalproduct",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/subfinalproduct/Editsubfinalproduct",
    component: <EditsubProduct />,
  },
  {
    type: "",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "",
    name: "Add News",
    key: "news",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/news/addnews",
    component: <Addnews />,
  },
  {
    type: "",
    name: "Edit News",
    key: "news",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/news/editnews",
    component: <Editnews />,
  },
  {
    type: "",
    name: "Edit Fashion Category",
    key: "fashioncategory",
    icon: <Icon fontSize="small">categoryIcon</Icon>,
    route: "/fashioncategory/Editfashioncategory",
    component: <EditCategory />,
  },
  {
    type: "",
    name: "Add Fashion Category",
    key: "fashioncategory",
    icon: <Icon fontSize="small">contactsIcon</Icon>,
    route: "/fashioncategory/Addfashioncategory",
    component: <AddCategory />,
  },
  {
    type: "collapse",
    name: "Contact Us",
    key: "contactus",
    icon: <Icon fontSize="small">contactsIcon</Icon>,
    route: "/contactus",
    component: <ContactUs />,
  },
  {
    type: "collapse",
    name: "Privacy Policy",
    key: "privacypolicy",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/privacypolicy",
    component: <Privacypolicy />,
  },
  {
    type: "collapse",
    name: "Terms and Conditions",
    key: "termsconditions",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/termsconditions",
    component: <Termscondition />,
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/changepassword",
    component: <Changepassword />,
  },
  {
    type: "",
    name: "Edit brand",
    key: "editbrand",
    icon: <Icon fontSize="small">Edit brand</Icon>,
    route: "/Editbrand",
    component: <Editbrand />,
  },
  {
    type: "",
    name: "Upload",
    key: "brandlist",
    icon: <Icon fontSize="small">Upload csv</Icon>,
    route: "/brandlist/uploadcsv",
    component: <UploadCSV />,
  },
];
// const routes = [
//   {
//     type: "collapse",
//     name: "Dashboard",
//     key: "dashboard",
//     icon: <Icon fontSize="small">dashboard</Icon>,
//     route: "/dashboard",
//     component: <Dashboard />,
//   },
//   {
//     type: "collapse",
//     name: "Tables",
//     key: "tables",
//     icon: <Icon fontSize="small">table_view</Icon>,
//     route: "/tables",
//     component: <Tables />,
//   },
//   {
//     type: "collapse",
//     name: "Billing",
//     key: "billing",
//     icon: <Icon fontSize="small">receipt_long</Icon>,
//     route: "/billing",
//     component: <Billing />,
//   },
//   {
//     type: "collapse",
//     name: "RTL",
//     key: "rtl",
//     icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
//     route: "/rtl",
//     component: <RTL />,
//   },
//   {
//     type: "collapse",
//     name: "Notifications",
//     key: "notifications",
//     icon: <Icon fontSize="small">notifications</Icon>,
//     route: "/notifications",
//     component: <Notifications />,
//   },
//   {
//     type: "collapse",
//     name: "Profile",
//     key: "profile",
//     icon: <Icon fontSize="small">person</Icon>,
//     route: "/profile",
//     component: <Profile />,
//   },
//   {
//     type: "collapse",
//     name: "Sign In",
//     key: "sign-in",
//     icon: <Icon fontSize="small">login</Icon>,
//     route: "/authentication/sign-in",
//     component: <SignIn />,
//   },
//   // {
//   //   type: "collapse",
//   //   name: "Sign Up",
//   //   key: "sign-up",
//   //   icon: <Icon fontSize="small">assignment</Icon>,
//   //   route: "/authentication/sign-up",
//   //   component: <SignUp />,
//   // },
// ];

export default routes;
