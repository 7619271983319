import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "../../Schemas/Apiurl";
import Loader from "components/Loader/Loader";
import useLoading from "components/Loader/useLoading";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Headers } from "hooks/Fetchdata";
// import { useNavigate } from "react-router-dom";
import { SendNewsletterSchemas } from "Schemas";
import { toast } from "react-toastify";

const SendNewsletter = () => {
  const initialValues = {
    subject: "",
    title: "",
    body: "",
    user_type: "all",
  };

  const header = Headers();
  // const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const onSubmit = async (value, { resetForm }) => {
    startLoading();
    try {
      const res = await axios.post(endpoints.sendnewslater, values, header);
      if (res.data.status === 1) {
        // navigate("/news");
      }
      stopLoading();
      //   console.log(res);
      if (res.data.status === 1) {
        resetForm();
        toast.success(res.data.message);
      }
    } catch (error) {
      //   console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: SendNewsletterSchemas,
    });

  const usertype = ["user", "influencer", "brand", "store", "all"];

  const Uppercase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <div className="d-flex gap-2 mt-2">
            {usertype.map((item, i) => {
              return (
                <button
                  key={i}
                  type="button"
                  style={{
                    backgroundColor: values.user_type === item ? "#495057" : "",
                    color: values.user_type === item ? "#ffffff" : "",
                  }}
                  className="usertypebtn px-2"
                  onClick={() => setFieldValue("user_type", item)}
                >
                  {Uppercase(item)}
                </button>
              );
            })}
          </div>
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="subject"
            onBlur={handleBlur}
            value={values.subject}
            label="Subject"
            fullWidth
          />
          {errors.subject ? touched.subject && <p className="errortag">{errors.subject}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="title"
            onBlur={handleBlur}
            value={values.title}
            label="Title"
            fullWidth
          />
          {errors.title ? touched.title && <p className="errortag">{errors.title}</p> : null}
        </MDBox>
        <MDBox mb={2}>
          <CKEditor
            editor={ClassicEditor}
            data={values.body}
            onChange={(e, editor) => setFieldValue("body", editor.getData())}
          />
          {errors.body ? touched.body && <p className="errortag">{errors.body}</p> : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default SendNewsletter;
