/* eslint-disable react/prop-types */
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const Replymodal = ({ reply, handleClose, handlereply, settext, text }) => {
  return (
    <>
      <Modal
        show={reply.isOpen}
        onHide={handleClose}
        backdrop="static"
        size="md"
        className="z-index-add-media"
        centered
      >
        <Modal.Header closeButton className="border-0">
          <h1>{reply?.id?.name || reply?.id?.user_detail?.full_name}</h1>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive-add">
            <form onSubmit={handlereply} className="d-flex flex-column align-items-center gap-2">
              <span>{reply?.id?.email}</span>
              <input
                type="text"
                placeholder="Subject"
                required
                value={text.subject || ""}
                className="w-100 p-2 rounded-2 border-1 border-black"
                onChange={(e) => settext((prev) => ({ ...prev, subject: e.target.value }))}
              />
              <textarea
                name=""
                id=""
                className="w-100"
                style={{
                  height: "200px",
                  borderRadius: "10px",
                  outline: "none",
                  padding: "10px",
                }}
                // value={text.reply}
                onChange={(e) => settext((prev) => ({ ...prev, reply: e.target.value }))}
                placeholder="reply"
                required
              ></textarea>
              <button className="createbrandbtn" style={{ width: "100px" }} type="submit">
                Reply
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

Replymodal.propTypes = {
  reply: PropTypes.string.isRequired, // Assuming reply is a string and required
  handleClose: PropTypes.func.isRequired, // Assuming handleClose is a function and required
  setReply: PropTypes.func.isRequired, // Assuming setReply is a function and required
  handleReply: PropTypes.func.isRequired, // Assuming handleReply is a function and required
  setText: PropTypes.func.isRequired, // Assuming setText is a function and required
  text: PropTypes.object.isRequired, // Assuming text is a string and required
};

export default Replymodal;
