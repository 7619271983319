import { useCallback, useContext, useEffect, useState } from "react";
import Button from "../../components/MDButton";
// import { Icons } from "@/public/Icons";
import "./Editbrand.css";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
// import { useDropzone } from "react-dropzone";
import emailicon from "../../assets/edit-button-svgrepo-com.svg";
import usericon from "../../assets/edit-button-svgrepo-com.svg";
// import passicon from "../../public/Assets/Password.svg";
// import eyeopen from "../../public/Assets/eye.svg";
// import closeeye from "../../public/Assets/eye.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import Selecttag from "../Input/Select";
// import Select from "react-select";
import { IoMdClose } from "react-icons/io";
// import { sociallinks } from "@/public/Images";
// import { price_range_digits } from "@/json/Dummydata";
import { savepagenumber } from "../../redux/authSlice";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import Loader from "components/Loader/Loader";
import useLoading from "components/Loader/useLoading";
import Simpleloader from "components/Loader/Simpleloader";
import { Skeleton } from "@mui/material";
import axios from "../../Schemas/Apiurl";
import Fetchdata from "hooks/Fetchdata";
import { endpoints } from "endpoints";
import { Headers } from "hooks/Fetchdata";
import { Contenttype } from "hooks/Fetchdata";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

const Editbrand = () => {
  const route = useLocation();
  const maindata = route?.state;
  // console.log(maindata);
  const [accept, setaccept] = useState(false);
  const [combinedObject, setCombinedObject] = useState([]);
  const langState = useSelector((state) => state.langSlice);
  const langid = langState ? langState.langId : null;
  const { loading, startLoading, stopLoading } = useLoading();
  const userdata = useSelector((state) => state.auth.user);
  const [image, setimage] = useState({});
  const [number, setnumber] = useState();
  //   const { isupdate, setisupdate } = useContext(GlobalContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setfile] = useState({ file: null, url: null });
  const { response, getdata } = Fetchdata(
    endpoints.getbrandditailsbyadmin,
    { lang_id: langid, user_id: maindata?.id },
    "POST"
  );

  const {
    response: country,
    getdata: getcountry,
    isloading: isLoading,
  } = Fetchdata(endpoints.getcountryditails, {}, "GET");

  useEffect(() => {
    getdata();
    getcountry();
  }, []);

  const initialValues = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    phone: "",
    // user_name: "",
    address: "",
    late: "",
    long: "",
    country_code: "",
    brand_name: "",
    founding_year: null,
    founded_country: "",
    country_of_manufacture: null,
    price_range_digits: "",
    about: "",
    logo: "",
    about_video_link: "",
    header_image: "",
    social_links: [],
    commercial_video_link: [],
    brand_values: [],
    brand_category: null,
    categories: null,
    certification: null,
    user_id: "",
  };
  const onDrop = useCallback(async (acceptedFiles) => {
    // uploadImage(acceptedFiles[0]);
    if (acceptedFiles.length > 1) {
      toast.error("only one file can be selected");
    } else {
      const formdata = new FormData();
      formdata.append("image", acceptedFiles[0]);
      startLoading();
      try {
        const res = await axios.post(endpoints.uploadimage, formdata, header2);
        setfile({
          file: acceptedFiles[0],
          url: URL.createObjectURL(acceptedFiles[0]),
        });
        stopLoading();
        setFieldValue("logo", res.data.data);
        const { name, type, path } = acceptedFiles[0];
        setimage({ name, type, path });
      } catch (error) {
        // console.log(error);
        stopLoading();
      }
    }
  }, []);

  //   const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //     onDrop,
  //     accept: "image/*",
  //   });

  function convertbrandvalue(data) {
    const result = data?.map((item) => ({
      brand_values_id: item.brand_values_id.id,
      subbrand_id: item.subbrand_id.id,
    }));
    return result;
  }
  const header = Headers();
  const header2 = Headers(Contenttype.formdata);
  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.updatebranddetails,
        {
          fname: values.fname,
          lname: values.lname,
          country_code: values.country_code,
          phone: values.phone,
          user_id: values.user_id,
          brand_name: values.brand_name,
          founding_year: values.founding_year,
          founded_country: values.founded_country,
          brand_category: values?.brand_category?.map((item) => item.id).join(","),
          country_of_manufacture: values?.country_of_manufacture?.map((item) => item.id).join(","),
          price_range_digits: values?.price_range_digits,
          about: values.about,
          logo: values.logo,
          header_image: "",
          social_links: values.social_links,
          about_video_link: values?.about_video_link,
          brand_values: convertbrandvalue(values?.brand_values),
          categories: combinedObject,
          certification: values?.certification?.map((item) => item.id).join(","),
        },
        header
      );
      // console.log(res);
      if (res.data.status === 1) {
        navigate.push("/successful");
        // setisupdate((prev) => !prev);
      }
      stopLoading();
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      onSubmit,
      initialValues: initialValues,
      // validationSchema: Brandinfoschemas,
    });

  const handleOnChange = (phoneNumber, country) => {
    setFieldValue("phone", phoneNumber?.replace(country.dialCode, ""));
    setFieldValue("country_code", `+${country.dialCode}`);
    setnumber(phoneNumber);
  };

  function convertResponse(data) {
    const result = data?.map((item) => ({
      categories: item.categories.id,
      subcategory: item.subcategory.map((sub) => sub.id).join(","),
      type: item.type.map((t) => t.id).join(","),
      sub_type: item.sub_type.map((st) => st.id).join(","),
      specific_item: item.specific_item.map((si) => si.id).join(","),
    }));
    return result;
  }

  useEffect(() => {
    for (const key of Object.keys(initialValues)) {
      setFieldValue(key, response?.data[key]);
    }
    setfile({ url: response?.data?.logo });
    dispatch(savepagenumber(response?.data?.page));
    const transformedData = convertResponse(values?.categories);
    setCombinedObject(transformedData);
    const num = response?.data?.country_code + response?.data?.phone;
    setnumber(num);
  }, [response]);

  const handlecountryChange = (selectedOptions) => {
    setFieldValue(
      "country_of_manufacture",
      selectedOptions.map((item) => ({ value: item.value, label: item.label }))
    );
  };

  const options = country?.data?.map((item) => ({
    value: item.CountryId,
    label: item.CountryName,
  }));

  const handledelete = (item, i) => {
    const newdata = values.social_links.filter((value, index) => index !== i);
    setFieldValue("social_links", newdata);
  };

  const handlevideodelete = async (name) => {
    startLoading();
    const param =
      name === "logo"
        ? values.logo?.split("/")?.at(-1)
        : values?.about_video_link?.split("/")?.at(-1);
    try {
      const res = await axios.post(
        endpoints.delete_image,
        {
          img: param,
        },
        header
      );
      stopLoading();
      // console.log(res);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        setFieldValue(name, "");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  let t = () => {};
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <form onSubmit={handleSubmit} className="d-flex flex-column gap-4">
        <div
          onClick={() => navigate.back()}
          className="d-flex gap-2 align-items-center "
          style={{ cursor: "pointer" }}
        >
          {/* <span>{Icons.backarrow}</span> */}
          <span>{t("Back")}</span>
        </div>
        <div className="inputfieldss">
          <div className="d-flex align-items-center gap-2 flex-wrap flex-md-nowrap">
            <div>
              <span>{t("firstname")}</span>
              <MDInput
                icon1={usericon}
                label={isLoading ? "Loading..." : "First Name"}
                width={"100%"}
                onChange={handleChange}
                onBlur={handleBlur}
                name={"fname"}
                value={values.fname}
                disabled={true}
                type="text"
                fullWidth
              />
            </div>
            <div>
              <span>{t("lastname")}</span>
              <MDInput
                label={isLoading ? "Loading..." : "Last Name"}
                width={"100%"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lname}
                name={"lname"}
                disabled={true}
                type="text"
                fullWidth
              />
            </div>
          </div>
          <div>
            <span>{t("CompanyEmail")}</span>
            <MDInput
              icon1={emailicon}
              label={isLoading ? "Loading..." : "Email"}
              width={"100%"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              name={"email"}
              disabled={true}
              type="text"
              fullWidth
            />
          </div>
          <div className="d-flex align-items-center gap-2 flex-wrap flex-md-nowrap w-100">
            <div className="w-100">
              <span>{t("PhoneNumber")}</span>
              <PhoneInput
                country={"us"}
                value={number ? number : ""}
                onChange={handleOnChange}
              />{" "}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <b>{t("BrandInformation")}</b>
        </div>{" "}
        <div className="inputfieldss">
          <div className="d-flex flex-wrap flex-md-nowrap gap-2">
            <div className="w-100">
              <span>{t("BrandName")}</span>
              <MDInput
                label={isLoading ? "Loading..." : "Brand Name"}
                width={"100%"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.brand_name}
                name={"brand_name"}
                type="text"
                fullWidth
              />
            </div>
            <div className="w-100">
              <span>{t("FoundingYear")}</span>

              <MDInput
                label={isLoading ? "Loading..." : "Founding Year"}
                width={"100%"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.founding_year}
                name={"founding_year"}
                type="text"
                fullWidth
              />
            </div>
          </div>
          <div className="d-flex flex-wrap flex-md-nowrap gap-2">
            <div className="w-100">
              <span>{t("FoundedCountry")}</span>
              {/* <Selecttag
                name={"founded_country"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.founded_country}
              >
                <option value="" selected disabled>
                  Select
                </option>
                {country?.data?.map((item) => {
                  return (
                    <option value={item.CountryName} key={item.CountryId}>
                      {item.CountryName}
                    </option>
                  );
                })}
              </Selecttag> */}
            </div>
            <div className="w-100">
              <span>{t("CountryofManufacture")}</span>
              {/* <Select
                options={options}
                isMulti
                value={values?.country_of_manufacture?.map((item) => ({
                  value: item.id || item.value,
                  label: item.name || item.label,
                }))}
                onChange={handlecountryChange}
                name="country_of_manufacture"
                className="basic-multi-select"
                classNamePrefix="select"
                closeMenuOnSelect={false}
              /> */}
            </div>
          </div>
          <div className="brandinfo">
            <span>{t("PriceClass")}</span>
            <div className="pricebox">
              {/* {price_range_digits.map((item, i) => {
                return (
                  <div
                    style={{
                      backgroundColor: values.price_range_digits === item.value ? "#000000" : "",
                      color: values.price_range_digits === item.value ? "#ffffff" : "#000000",
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={() => setFieldValue("price_range_digits", item.value)}
                  >
                    {item.name}
                  </div>
                );
              })} */}
            </div>
            {errors.price_range_digits && touched.price_range_digits ? (
              <p className="error">{errors.price_range_digits}</p>
            ) : null}
          </div>
          <div className="brandinfo">
            <span>{t("AbouttheBrand")}</span>
            <textarea
              placeholder="About the Brand"
              onChange={handleChange}
              onBlur={handleBlur}
              name="about"
              value={values.about}
              id=""
            ></textarea>
            {errors.about && touched.about ? <p className="error">{errors.about}</p> : null}
          </div>
          <div className="brandinfo">
            <span>{t("BrandLogo")}</span>
            {/* <div
              {...getRootProps()}
              style={{
                border: "3px solid #ebebeb",
                width: "100%",
                height: "100%",
                minHeight: "150px",
                borderStyle: "dotted",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
              }}
              className={isDragActive ? "dragndropactive" : "dragndrop"}
            >
              <input {...getInputProps()} accept="image/*" />
              <>
                {!file.url ? (
                  <div className="d-flex flex-column align-items-center gap-2">
                    <Image unoptimized style={{ width: "50px" }} src={Dragdrop} alt="" />
                    <p>
                      <span>Browse</span> image or drag and drop here
                    </p>
                  </div>
                ) : (
                  <div className="d-flex align-items-center flex-column gap-2">
                    <p>{image.name} </p>
                    <div className="d-flex  justify-content-center gap-2">
                      <img src={file.url} alt={image.type} width="20%" />
                      {editbrand && (
                        <IoIosCloseCircle
                          style={{ cursor: "pointer" }}
                          onClick={() => handlevideodelete("logo")}
                          size={30}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            </div>{" "} */}
            {errors.logo && touched.logo ? <p className="error">{errors.logo}</p> : null}
          </div>
          <div className="brandinfo">
            <span>{t("Aboutvideolink")}</span>
            <MDInput
              name={"about_video_link"}
              onChange={handleChange}
              onBlur={handleBlur}
              type={"text"}
              label={"Link"}
              value={values.about_video_link}
              fullWidth
            />
            {values?.about_video_link !== "" && (
              <iframe src={values.about_video_link} frameBorder="0"></iframe>
            )}
            {errors.about_video_link && touched.about_video_link ? (
              <p className="error">{errors.about_video_link}</p>
            ) : null}
          </div>
          <div className="brandinfo">
            <span>{t("socialliks")}</span>
            <div className="d-flex align-items-center gap-2 flex-wrap sociallinksicons">
              {/* {sociallinks.map((item, i) => {
                const exists = values?.social_links?.some((link) => link.Name === item.name);
                return (
                  <div
                    style={{
                      backgroundColor: exists ? "#F0F0F0" : "",
                      cursor: "pointer",
                    }}
                    key={i}
                    onClick={() => {
                      if (!exists) {
                        setFieldValue("social_links", [
                          ...values.social_links,
                          {
                            Name: item.name,
                            Link: "",
                          },
                        ]);
                      }
                    }}
                  >
                    <Image src={item.image} unoptimized alt="" />
                  </div>
                );
              })} */}
            </div>
            {isLoading ? (
              <Skeleton width={250} height={45} variant="rounded" />
            ) : (
              <>
                {values?.social_links?.map((item, i) => {
                  return (
                    <div key={i} className="d-flex flex-column gap-1">
                      <span>{item.Name}</span>
                      <div className="d-flex align-items-center gap-2">
                        {/* <Input
                          required={true}
                          placeholder={"www.example.com/instasdiosfwe"}
                          width={"100%"}
                          value={item.Link}
                          onChange={(e) => {
                            const newSocialLinks = [...values.social_links];
                            newSocialLinks[i].Link = e.target.value;
                            setFieldValue("social_links", newSocialLinks);
                          }}
                        /> */}
                        <IoMdClose size={30} onClick={() => handledelete(item, i)} />
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <b>Brand Values</b>
        </div>{" "}
        {isLoading ? (
          <Simpleloader />
        ) : (
          <div className="d-flex align-items-center gap-3">
            <div className="d-flex flex-column gap-2">
              <b>Values</b>
              {values?.brand_values?.map((item, i) => {
                return <li key={i}>{item.brand_values_id.title}</li>;
              })}
            </div>
            <div className="d-flex flex-column gap-2">
              <b>Sub-Value</b>
              {values?.brand_values?.map((item, i) => {
                return <li key={i}>{item.subbrand_id.title}</li>;
              })}
            </div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between w-100">
          <b>Clothing Styles</b>
        </div>{" "}
        <div className="d-flex flex-column gap-3">
          {values?.brand_category?.map((item) => {
            return <li key={item.CountryId}>{item.name}</li>;
          })}
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <b>Categories</b>
        </div>{" "}
        <div>
          <div className="d-flex justify-content-between gap-3 flex-wrap mt-2">
            <div className="d-flex flex-column gap-2">
              <b>Categories</b>
              {values?.categories && <li>{values?.categories[0]?.categories?.category}</li>}
            </div>
            <div className="d-flex flex-column gap-2">
              <b>Sub categories</b>
              {values?.categories &&
                values?.categories[0]?.subcategory?.map((item) => {
                  return <li key={item.id}>{item.subcategory}</li>;
                })}
            </div>
            <div className="d-flex flex-column gap-2">
              <b>Types</b>
              {values?.categories &&
                values?.categories[0]?.type?.map((item) => {
                  return <li key={item.id}>{item.type}</li>;
                })}
            </div>
            <div className="d-flex flex-column gap-2">
              <b>Sub type</b>
              {values?.categories &&
                values?.categories[0]?.sub_type?.map((item) => {
                  return <li key={item.id}>{item.subtype}</li>;
                })}
            </div>
            <div className="d-flex flex-column gap-2">
              <b>Specific item</b>
              {values?.categories &&
                values?.categories[0]?.specific_item?.map((item) => {
                  return <li key={item.id}>{item.specificitem}</li>;
                })}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between w-100">
          <b>Certificates and Standards</b>
        </div>{" "}
        {isLoading ? (
          <Simpleloader />
        ) : (
          <div className="certificate">
            {values?.certification?.map((item, i) => {
              return (
                <div className="certificateand" key={item.id}>
                  <div>
                    <img src={item.image} alt="" />
                  </div>
                  <span> {item.title}</span>
                </div>
              );
            })}
          </div>
        )}
        <div className="d-flex flex-column gap-3">
          <p>{t("ifallinfo")}</p>
        </div>
        <Button
          type={"submit"}
          backcolor={"#000000"}
          color={"#ffffff"}
          width={"150px"}
          height={45}
          disabled={isLoading || !accept ? true : false}
        >
          {t("submit")}
        </Button>
      </form>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default Editbrand;
