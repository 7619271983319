import useLoading from "components/Loader/useLoading";
import axios from "../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { AddnewsSchemas } from "Schemas";
import { toast } from "react-toastify";
import { useRef } from "react";

const UploadCSV = () => {
  const fileInputRef = useRef(null);

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the file input
    }
  };
  const initialValues = {
    csv_file: "",
  };

  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  //   const header = Headers();
  const header2 = Headers(Contenttype.formdata);
  const onSubmit = async () => {
    if (values.csv_file && values.csv_file.type === "text/csv") {
      startLoading();
      const formdata = new FormData();
      formdata.append("csv_file", values.csv_file);
      try {
        const res = await axios.post(endpoints.uploadbrandsheet, formdata, header2);
        if (res.data.status === 1) {
        }
        stopLoading();
        // console.log(res);
        if (res.data.status === 1) {
          toast.success(res.data.message);
          navigate("/brandlist");
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        // console.log(error);
        toast.error(error?.response?.data?.message);
        stopLoading();
      }
    } else {
      toast.error("Please upload a valid CSV file.");
      setFieldValue("csv_file", "");
      resetFileInput();
    }
  };

  const { handleSubmit, handleBlur, setFieldValue, values, errors, touched } = useFormik({
    initialValues: initialValues,
    onSubmit,
    //   validationSchema: AddnewsSchemas,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <input
            ref={fileInputRef}
            type="file"
            // onChange={(e) => setFieldValue("csv_file", e.target.files[0])}
            onChange={(e) => {
              const file = e.target.files[0];
              if (file && file.type === "text/csv") {
                setFieldValue("csv_file", file);
              } else {
                // alert("Please upload a valid CSV file.");
                toast.error("Please upload a valid CSV file.");
                setFieldValue("csv_file", "");
                resetFileInput();
              }
            }}
            name="csv_file"
            accept=".csv"
            onBlur={handleBlur}
            // fullWidth
            className="w-100"
            style={{ border: "1px solid #d2dad6", padding: "10px", borderRadius: "5px" }}
            required
          />
          {/* {errors.TITLE_ENGLISH
            ? touched.TITLE_ENGLISH && <p className="errortag">{errors.TITLE_ENGLISH}</p>
            : null} */}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Upload
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default UploadCSV;
