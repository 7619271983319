/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Switch from "@mui/material/Switch";
// import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/landingpageimage.png";
import { useFormik } from "formik";
import axios from "../../../Schemas/Apiurl";
import { endpoints } from "endpoints";
import useLoading from "components/Loader/useLoading";
import Loader from "components/Loader/Loader";
import { toast } from "react-toastify";
import { Headers } from "hooks/Fetchdata";
import { ChangepassSchemas } from "Schemas";
// import { useDispatch, useSelector } from "react-redux";

function changeBasic() {
  const initialValues = {
    oldpassword: "",
    password: "",
  };

  const { loading, startLoading, stopLoading } = useLoading();
  const navigate = useNavigate();
  const header = Headers();
  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(
        endpoints.changepassword,
        {
          oldpassword: values.oldpassword,
          password: values.password,
        },
        header
      );
      if (res.data.status === 1) {
        toast.success(res.data.message);
        navigate("/dashboard");
      } else {
        toast.error(res.data.message);
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      // console.log(error);
      toast.error(error?.response?.data?.message);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
    initialValues: initialValues,
    onSubmit,
    validationSchema: ChangepassSchemas,
  });

  // const userdata = useSelector((state) => state.auth.user);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (!userdata?.token) {
  //     // dispatch(setsession(true));
  //   }
  // }, [userdata]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Change password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" onSubmit={handleSubmit} role="form">
            <MDBox mb={2}>
              <MDInput
                type="password"
                onChange={handleChange}
                name="oldpassword"
                onBlur={handleBlur}
                label="Old password"
                fullWidth
              />
              {errors.oldpassword
                ? touched.oldpassword && <p className="errortag">{errors.oldpassword}</p>
                : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                onChange={handleChange}
                name="password"
                onBlur={handleBlur}
                label="New password"
                fullWidth
              />
              {errors.password
                ? touched.password && <p className="errortag">{errors.password}</p>
                : null}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {loading && <Loader />}
    </BasicLayout>
  );
}

export default changeBasic;
