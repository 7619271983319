import useLoading from "components/Loader/useLoading";
import axios from "../../Schemas/Apiurl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { endpoints } from "endpoints";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useFormik } from "formik";
import { Contenttype } from "hooks/Fetchdata";
import { Headers } from "hooks/Fetchdata";
import Loader from "../../components/Loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import Fetchdata from "../../hooks/Fetchdata";
import { Fragment, useEffect, useState } from "react";
import { AddnewsSchemas } from "Schemas";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";

const Editnews = () => {
  const initialValues = {
    id: "",
    TITLE_ENGLISH: "",
    TITLE_GERMAN: "",
    TITLE_FRENCH: "",
    TITLE_ITALIAN: "",
    TITLE_SPANISH: "",
    DESCRIPTION_ENGLISH: "",
    DESCRIPTION_GERMAN: "",
    DESCRIPTION_FRENCH: "",
    DESCRIPTION_ITALIAN: "",
    DESCRIPTION_SPANISH: "",
    title_image: "",
    images: "",
  };

  const route = useLocation();
  const maindata = route?.state?.item;
  const navigate = useNavigate();
  const { loading, startLoading, stopLoading } = useLoading();
  const [image, setimage] = useState();
  const [multiImage, setmultiImage] = useState([]);
  const { response, isloading } = Fetchdata(
    endpoints.getnewsditailsadmin,
    { id: maindata?.id },
    "POST"
  );

  const header = Headers();
  const header2 = Headers(Contenttype.formdata);
  const onSubmit = async () => {
    startLoading();
    try {
      const res = await axios.post(endpoints.updatenewsbyadmin, values, header);
      if (res.data.status === 1) {
        navigate("/news");
      }
      stopLoading();
      // console.log(res);
    } catch (error) {
      // console.log(error);
      stopLoading();
    }
  };

  const { handleChange, handleSubmit, handleBlur, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
      validationSchema: AddnewsSchemas,
    });

  useEffect(() => {
    for (const key of Object.keys(initialValues)) {
      setFieldValue(key, response?.data[key]);
    }
    setFieldValue("id", maindata?.id);
    setimage(response?.data?.title_image);
    setmultiImage(response?.data?.news_images);
  }, [response]);

  const handleimage = async (e, name) => {
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file);
    startLoading();
    try {
      const res = await axios.post(endpoints.upload_image, formdata, header2);
      if (res.data.status === 1) {
        setFieldValue(name, res.data.data);
        setimage(res.data.data);
      }
      // console.log(res);
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
    }
  };

  const handledelete = async (item) => {
    startLoading();
    try {
      const res = await axios.post(endpoints.delete_image, { img: item }, header);
      if (res.data.status === 1) {
        setFieldValue("title_image", "");
        setimage("");
        toast.success(res.data.message);
      } else if (res.data.status === 0) {
        toast.error(res.data.message);
      }
      stopLoading();
    } catch (error) {
      console.log(error);
      stopLoading();
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.message === "Image not found") {
        setFieldValue("title_image", "");
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form" onSubmit={handleSubmit} role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            value={values.TITLE_ENGLISH}
            name="TITLE_ENGLISH"
            onBlur={handleBlur}
            label="Title english"
            fullWidth
          />
          {errors.TITLE_ENGLISH
            ? touched.TITLE_ENGLISH && <p className="errortag">{errors.TITLE_ENGLISH}</p>
            : null}{" "}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_GERMAN"
            value={values.TITLE_GERMAN}
            onBlur={handleBlur}
            label="Title german"
            fullWidth
          />
          {errors.TITLE_GERMAN
            ? touched.TITLE_GERMAN && <p className="errortag">{errors.TITLE_GERMAN}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_FRENCH"
            value={values.TITLE_FRENCH}
            onBlur={handleBlur}
            label="Title french"
            fullWidth
          />
          {errors.TITLE_FRENCH
            ? touched.TITLE_FRENCH && <p className="errortag">{errors.TITLE_FRENCH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_ITALIAN"
            value={values.TITLE_ITALIAN}
            onBlur={handleBlur}
            label="Title italian"
            fullWidth
          />
          {errors.TITLE_ITALIAN
            ? touched.TITLE_ITALIAN && <p className="errortag">{errors.TITLE_ITALIAN}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="text"
            onChange={handleChange}
            name="TITLE_SPANISH"
            value={values.TITLE_SPANISH}
            onBlur={handleBlur}
            label="Title spanish"
            fullWidth
          />
          {errors.TITLE_SPANISH
            ? touched.TITLE_SPANISH && <p className="errortag">{errors.TITLE_SPANISH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            value={values.DESCRIPTION_ENGLISH}
            name="DESCRIPTION_ENGLISH"
            onBlur={handleBlur}
            placeholder="DESCRIPTION ENGLISH"
          />
          {errors.DESCRIPTION_ENGLISH
            ? touched.DESCRIPTION_ENGLISH && (
                <p className="errortag">{errors.DESCRIPTION_ENGLISH}</p>
              )
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            value={values.DESCRIPTION_GERMAN}
            name="DESCRIPTION_GERMAN"
            onBlur={handleBlur}
            placeholder="DESCRIPTION GERMAN"
          />
          {errors.DESCRIPTION_GERMAN
            ? touched.DESCRIPTION_GERMAN && <p className="errortag">{errors.DESCRIPTION_GERMAN}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            value={values.DESCRIPTION_FRENCH}
            name="DESCRIPTION_FRENCH"
            onBlur={handleBlur}
            placeholder="DESCRIPTION FRENCH"
          />
          {errors.DESCRIPTION_FRENCH
            ? touched.DESCRIPTION_FRENCH && <p className="errortag">{errors.DESCRIPTION_FRENCH}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            value={values.DESCRIPTION_ITALIAN}
            name="DESCRIPTION_ITALIAN"
            onBlur={handleBlur}
            placeholder="DESCRIPTION ITALIAN"
          />
          {errors.DESCRIPTION_ITALIAN
            ? touched.DESCRIPTION_ITALIAN && (
                <p className="errortag">{errors.DESCRIPTION_ITALIAN}</p>
              )
            : null}
        </MDBox>
        <MDBox mb={2}>
          <textarea
            className="textareaaa"
            onChange={handleChange}
            value={values.DESCRIPTION_SPANISH}
            name="DESCRIPTION_SPANISH"
            onBlur={handleBlur}
            placeholder="DESCRIPTION SPANISH"
          />
          {errors.DESCRIPTION_SPANISH
            ? touched.DESCRIPTION_SPANISH && (
                <p className="errortag">{errors.DESCRIPTION_SPANISH}</p>
              )
            : null}
        </MDBox>
        <MDBox mb={2}>
          <span>Title image</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "title_image")}
            name="title_image"
            onBlur={handleBlur}
            disabled={values.title_image !== "" ? true : false}
            // label="Title spanish"
            fullWidth
          />
          <i style={{ fontSize: "14px" }}>If you want to edit image please delete old image</i>
          <div className="d-flex gap-1 mt-2">
            <img src={image} alt="" style={{ width: "200px" }} />
            {values.title_image !== "" && (
              <CloseIcon onClick={() => handledelete(values.title_image, "title_image")} />
            )}{" "}
          </div>
          {errors.title_image
            ? touched.title_image && <p className="errortag">{errors.title_image}</p>
            : null}
        </MDBox>
        <MDBox mb={2}>
          <span>Images</span>
          <MDInput
            type="file"
            onChange={(e) => handleimage(e, "images")}
            name="images"
            onBlur={handleBlur}
            // label="Title spanish"
            fullWidth
          />
          <div className="d-flex gap-1 mt-2">
            {multiImage?.map((item, i) => {
              return (
                <Fragment key={i}>
                  <img style={{ width: "200px" }} src={item.images} alt="" />
                  <CloseIcon onClick={() => handledelete(item.images, "images")} />
                </Fragment>
              );
            })}
          </div>
          {errors.email ? touched.email && <p className="errortag">{errors.email}</p> : null}
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth>
            Submit
          </MDButton>
        </MDBox>
      </MDBox>
      {loading && <Loader />}
    </DashboardLayout>
  );
};

export default Editnews;
